import React from "react"

interface Props {
  variant?: "background" | "no-background"
  type?: "warning" | "error" | "info" | "success" | "white"
  children: React.ReactNode
  icon?: boolean
}

export const Tag = ({
  variant = "background",
  children,
  icon = false,
  type = "success",
}: Props) => {
  if (icon) {
    return (
      <span className={`tag tag-${type} tag-icon tag-${variant}`}>
        <span
          className={`icon-${
            type === "success" ? "tick-circle-fill" : "alert-circle-outline"
          } tag-icon-icon`}
        />
        <span className="tag-icon-text">{children}</span>
      </span>
    )
  } else {
    return <span className={`tag tag-${type} tag-${variant}`}>{children}</span>
  }
}
