import React from "react"

import { useAuth } from "../../hooks"
import { Button } from "../shared"

export const AccountNotApproved = () => {
  const authHook = useAuth()

  return (
    <div className="form-card">
      <h1 className="heading-large text-center mb-3">
        You do not have employer permissions
      </h1>
      <p className="form-card-paragraph text-center">
        This GotTheTest account signed up via the patient or provider dashboard.
        To access the employer portal you must sign up via the employer
        dashboard.
      </p>
      <p className="form-card-paragraph text-center">
        If you need this account to be converted to an employer account please
        email{" "}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="mailto:we@gotthetest.com"
        >
          we@gotthetest.com
        </a>
      </p>
      <Button
        variant="secondary"
        onClick={() => authHook?.signOut()}
        formCard={true}
      >
        Log out
      </Button>
    </div>
  )
}
