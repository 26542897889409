import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const InputButtonList = ({ name, label, optional, options }) => {
  const [field, meta, helpers] = useField(name)
  const { value } = field

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      <Label field={name} optional={optional}>
        {label}
      </Label>
      <div
        className={`input-button-list-options${
          meta.touched && meta.error ? " input-button-list-options-error" : ""
        }`}
      >
        {options && options.length > 0
          ? options.map((option) => (
              <button
                type="button"
                key={option.value}
                className={`input-button-list-option${
                  value === option.value
                    ? " input-button-list-option-active"
                    : ""
                }`}
                onClick={() => helpers.setValue(option.value)}
              >
                {option.label}
              </button>
            ))
          : null}
      </div>
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputButtonList.defaultProps = {
  optional: false,
}

InputButtonList.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
