import React from "react"

interface Props {
  children: React.ReactNode
  hasFormElements?: Boolean
  button?: boolean
  variant?: "small-margin"
}

export const FormLegend = ({
  children,
  hasFormElements = false,
  button = false,
  variant,
}: Props) => (
  <div
    className={`form-legend${
      hasFormElements ? " form-legend-has-elements" : ""
    }${button ? " form-legend-button" : ""}${
      variant ? ` form-legend-${variant}` : ""
    }`}
  >
    {children}
  </div>
)
