import React from "react"

import { Breadcrumb } from "./Breadcrumb"

export const Breadcrumbs = () => {
  return (
    <ul className="breadcrumbs">
      <Breadcrumb to="/" chevron={false}>
        Dashboard
      </Breadcrumb>
    </ul>
  )
}
