import React from "react"
import { CSVLink } from "react-csv"
import dayjs from "dayjs"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

import { StatusReportingGroupType } from "../../../types"
import { handleAPIError } from "../../../utilities"
import {
  Button,
  ButtonWithIcon,
  ButtonList,
  EmptyState,
  WarningModal,
} from "../../shared"
import { StatusReportingGroupTable } from "./StatusReportingGroupTable"
import { StatusReportingGroupCards } from "./StatusReportingGroupCards"
import { InviteEmployeesModal } from "./InviteEmployeesModal"

export const StatusReportingGroup = ({
  id,
  code,
  patients,
  setData,
}: StatusReportingGroupType & { setData: Function }) => {
  const { addToast } = useToasts()
  const [inviteModalVisible, setInviteModalVisible] = React.useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false)
  const [deleteButtonLoading, setDeleteButtonLoading] = React.useState(false)

  const CSVHeaders = [
    { label: "Patient ID", key: "id" },
    { label: "Patient", key: "fullName" },
    { label: "Vaccinated", key: "vaccinated" },
    { label: "Fit for Work", key: "fitForWork" },
  ]

  const deleteStatusReportingGroup = async () => {
    try {
      setDeleteButtonLoading(true)
      await axios({
        method: "post",
        url: "status-reporting-groups/delete",
        data: { statusReportingGroupId: id },
      })
      setData((groups: Array<StatusReportingGroupType>) =>
        groups.filter((group) => group.id !== id)
      )
      addToast(
        `${code} deleted, employees will no longer see this group in their accounts`,
        {
          appearance: "success",
        }
      )
      setDeleteButtonLoading(false)
    } catch (error) {
      addToast(handleAPIError(error, "delete status reporting group"), {
        appearance: "error",
      })
      setDeleteButtonLoading(false)
    }
  }

  return (
    <section className="background-white status-reporting-group">
      <header className="status-reporting-group-header">
        <h2 className="heading-mediumn">{code}</h2>
      </header>
      <section className="status-reporting-group-buttons">
        <ButtonList>
          <ButtonWithIcon
            icon="plus"
            onClick={() => setInviteModalVisible(true)}
          >
            Invite employees
          </ButtonWithIcon>
          <Button
            onClick={() => setDeleteModalVisible(true)}
            variant="secondary"
          >
            Delete group
          </Button>
          {patients && patients.length > 0 && (
            <CSVLink
              data={patients}
              headers={CSVHeaders}
              filename={`gotthetest-fit-for-work-status-${dayjs(
                Date.now()
              ).format("MM-DD-YYYY")}.csv`}
            >
              <ButtonWithIcon variant="secondary" icon="download">
                Download CSV
              </ButtonWithIcon>
            </CSVLink>
          )}
        </ButtonList>
      </section>

      <div className="status-reporting-group-body">
        {patients && patients.length > 0 ? (
          <>
            <StatusReportingGroupTable data={patients} />
            <StatusReportingGroupCards data={patients} />
          </>
        ) : (
          <EmptyState>
            <p className="text-small">
              No patients have joined this Status Reporting Group yet
            </p>
          </EmptyState>
        )}
      </div>
      <InviteEmployeesModal
        isVisible={inviteModalVisible}
        code={code}
        id={id}
        dismissFunc={() => setInviteModalVisible(false)}
      />
      <WarningModal
        isVisible={deleteModalVisible}
        dismissFunc={() => setDeleteModalVisible(false)}
        title={`Delete ${code}?`}
        deleteButtonText={`Delete ${code}`}
        deleteFunc={() => deleteStatusReportingGroup()}
        deleteButtonLoading={deleteButtonLoading}
      >
        <p>
          You will no longer be able able to see the fit for work status and
          vaccination status of employees who joined the {code}.
        </p>
      </WarningModal>
    </section>
  )
}
