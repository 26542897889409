import React from "react"
import { useTable, useSortBy } from "react-table"

import { Tag } from "../../../shared"

export const StatusReportingGroupTable = ({ data }: { data: any }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Patient ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Patient",
        accessor: "fullName",
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text list-page-table-body-cell-bold">
            {value}
          </span>
        ),
      },
      {
        Header: "Vaccinated",
        accessor: "vaccinated",
        Cell: ({ value }: { value: boolean }) => (
          <span className="list-page-table-body-cell-text">
            {value ? "Vaccinated" : "Not vaccinated"}
          </span>
        ),
      },
      {
        Header: "Fit for Work",
        accessor: "fitForWork",
        Cell: ({ value }: { value: boolean }) => (
          <React.Fragment>
            {value ? (
              <Tag type="success" variant="background" icon>
                Fit for work
              </Tag>
            ) : (
              <Tag type="error" variant="background" icon>
                Not fit for work
              </Tag>
            )}
          </React.Fragment>
        ),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <table className="list-page-table" {...getTableProps()}>
      <thead className="list-page-table-header">
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index: number) => (
              <th
                className={`list-page-table-header-cell${
                  column.isSorted ? " list-page-table-header-cell-sorted" : ""
                }${
                  index === 0 || index === 3
                    ? " list-page-table-header-cell-desktop"
                    : ""
                }`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <span className="list-page-table-header-cell-layout">
                  <span className="list-page-table-header-cell-text">
                    {column.render("Header")}
                  </span>
                  <span className="icon-sort list-page-table-header-cell-icon" />
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="list-page-table-body" {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row)
          return (
            <tr className="list-page-table-body-row" {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="list-page-table-body-cell"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
