import React from "react"

interface Props {
  icon?:
    | "arrow-left"
    | "arrow-right"
    | "arrow-top"
    | "arrow-bottom"
    | "clipboard"
    | "download"
    | "external-link"
    | "cross"
    | "email"
    | "filter"
    | "plus"
  iconPosition?: "left" | "right"
  variant?: "primary" | "secondary" | "tertiary" | "delete"
  children: React.ReactNode
  onClick?: () => void
  href?: string
  type?: "button" | "submit"
}

export const ButtonWithIcon = ({
  type = "button",
  icon = "arrow-right",
  iconPosition = "left",
  variant = "primary",
  children,
  onClick,
  href,
}: Props) => {
  if (onClick) {
    return (
      <button
        className={`button button-${variant} button-with-icon button-with-icon-${iconPosition}`}
        onClick={onClick}
        type={type}
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </button>
    )
  }
  if (href) {
    return (
      <a
        className={`button button-${variant} button-with-icon button-with-icon-${iconPosition}`}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </a>
    )
  } else {
    return (
      <button
        className={`button button-${variant} button-with-icon button-with-icon-${iconPosition}`}
        type={type}
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </button>
    )
  }
}
