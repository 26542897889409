import React from "react"
import axios from "axios"

interface Props {
  url: string
  requestData?: any
}

export const useQuery = ({ url, requestData }: Props) => {
  const [error, setError] = React.useState(null)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const query = async () => {
      try {
        const response = await axios({
          method: "post",
          url: url,
          data: requestData,
        })
        setData(response.data)
      } catch (error: any) {
        setError(error)
      }
      setLoading(false)
    }
    query()

    return () => {
      setError(null)
      setData([])
      setLoading(false)
    }
  }, []) // eslint-disable-line
  return { data, setData, error, loading }
}
