import React from "react"
import { useToasts } from "react-toast-notifications"

import { handleAPIError } from "../../../utilities"
import { Loader } from ".."

interface Props {
  additionalClasses?: string
  loading: boolean
  error?: {
    response?: {
      status?: number
    }
  } | null
  colour?: "orange" | "grey"
  action: string
}

export const Query = ({
  additionalClasses,
  loading,
  error,
  colour = "orange",
  action,
}: Props) => {
  const { addToast } = useToasts()

  React.useEffect(() => {
    if (error?.response?.status) {
      if (error.response.status === 401) {
        addToast(`Could not ${action}, your login session has expired`, {
          appearance: "error",
        })
      }
    }
  }, [error]) // eslint-disable-line

  if (loading) {
    return (
      <div
        className={`query${additionalClasses ? ` ${additionalClasses}` : ""}`}
      >
        <Loader colour={colour} />
      </div>
    )
  } else if (error) {
    return (
      <div
        className={`query-error${
          additionalClasses ? ` ${additionalClasses}` : ""
        }`}
      >
        <span className="icon-alert-circle-fill query-error-icon" />
        <p className="query-error-message">{handleAPIError(error, action)}</p>
      </div>
    )
  } else {
    return null
  }
}
