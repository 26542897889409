import React from "react"
import { Helmet } from "react-helmet"

import { ButtonWithIcon, Query } from "../shared"
import { useQuery } from "../../hooks"
import { NewStatusReportingGroup } from "./NewStatusReportingGroup"
import { SEOTitleTemplate } from "../../constants"
import { StatusReportingGroup } from "./StatusReportingGroup"
import { StatusReportingGroupType } from "../../types"

export const Dashboard = () => {
  const [newModalVisible, setNewModalVisible] = React.useState(false)
  let { data, setData, error, loading } = useQuery({
    url: "/status-reporting-groups/all-employer-groups",
  })

  return (
    <div>
      <Helmet title={SEOTitleTemplate({ title: "Dashboard" })} />
      <header className="dashboard-header">
        <h1 className="heading-large">Your Status Reporting Groups</h1>
        <ButtonWithIcon
          icon="plus"
          iconPosition="left"
          onClick={() => setNewModalVisible(true)}
        >
          New Status Reporting Group
        </ButtonWithIcon>
      </header>
      <div>
        {loading || error ? (
          <Query
            loading={loading}
            error={error}
            action="load status reporting groups"
          />
        ) : data && data.length > 0 ? (
          data.map((group: StatusReportingGroupType) => (
            <StatusReportingGroup
              key={group.id}
              id={group.id}
              code={group.code}
              patients={group?.patients}
              setData={setData}
            />
          ))
        ) : (
          <div className="background-white text-center p-6 flex-justify-center border-radius-2">
            <p className="width-100 mb-3">
              You aren't connected to any Status Reporting Groups yet
            </p>
            <ButtonWithIcon
              icon="plus"
              iconPosition="left"
              onClick={() => setNewModalVisible(true)}
            >
              New Status Reporting Group
            </ButtonWithIcon>
          </div>
        )}
      </div>
      <NewStatusReportingGroup
        dismissFunc={() => setNewModalVisible(false)}
        isVisible={newModalVisible}
        setData={setData}
      />
    </div>
  )
}
