import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import { Helmet } from "react-helmet"
import axios from "axios"
import { navigate, useLocation } from "@reach/router"
import { useQueryParam, StringParam } from "use-query-params"

import { useAuth } from "../../hooks"
import { Form, Input, InputPassword, Button } from "../shared"
import { SEOTitleTemplate } from "../../constants"
import { handleAPIError } from "../../utilities"

import { ReactComponent as GTTLogoDark } from "../../assets/GotTheTest-logo-dark.svg"

interface LoginFormValues {
  email: string
  password: string
}

export const Login = () => {
  const location = useLocation()
  const authHook = useAuth()
  const { addToast } = useToasts()
  const [code] = useQueryParam("code", StringParam)
  const [loading, setLoading] = React.useState(false)
  // get queryParams

  const onSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    setLoading(true)
    try {
      // setSubmitting(false) To prevent memory leak
      await authHook?.signIn({
        email: values.email,
        password: values.password,
        errorFunc: () => {
          setLoading(false)
          setSubmitting(false)
        },
      })
    } catch {
      addToast("Something went wrong, please try again", {
        appearance: "error",
      })
      setLoading(false)
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    const checkIfUserSignedUp = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `/user/${authHook?.user.uid}`,
        })
        const user = response.data

        if (!user.emailVerified) {
          authHook?.setCreatingAccount(true)
          if (code) {
            navigate(`/create-account/confirm-email?code=${code}`)
          } else {
            navigate("/create-account/confirm-email")
          }
        } else {
          authHook?.setCreatingAccount(false)
          authHook?.setSignedUp(true)

          if (user?.permissions?.isEmployer) {
            authHook?.setAccountApproved(true)
          } else {
            authHook?.setAccountApproved(false)
          }

          if (location.pathname === "/create-account/confirm-email") {
            navigate("/")
          }
        }
      } catch (error) {
        addToast(handleAPIError(error, "log in"), {
          appearance: "error",
        })
        setLoading(false)
      }
    }
    if (authHook?.user) {
      checkIfUserSignedUp()
    }
  }, [authHook?.user]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Login" })} />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your email"),
          password: Yup.string().required("Please enter your password"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <GTTLogoDark className="login-form-logo" />
            <h1 className="heading-medium text-center mt-6 mb-3">
              Employer dashboard
            </h1>
            <Input label="Email" name="email" />
            <InputPassword label="Password" name="password" />
            <Button
              type="submit"
              disabled={isSubmitting || loading}
              loading={isSubmitting || loading}
              formCard={true}
            >
              Log in
            </Button>
            <Button variant="secondary" to="/sign-up" formCard={true}>
              Sign up
            </Button>
            <Button variant="tertiary" to="/forgotten-password" formCard={true}>
              Forgotten your password?
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
