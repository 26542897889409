import React from "react"
import { Link } from "@reach/router"

import { useAuth } from "../../hooks"
import { Breadcrumbs } from "./Breadcrumbs"

import { ReactComponent as GTTLogoDark } from "../../assets/GotTheTest-logo-dark.svg"

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  const authHook = useAuth()

  return (
    <>
      <div className="nav">
        <Link className="nav-logo" to="/">
          <GTTLogoDark />
        </Link>
        <Breadcrumbs />
        <div className="nav-log-out">
          <button
            className="nav-log-out-button"
            onClick={() => authHook?.signOut()}
          >
            Log out
          </button>
        </div>
      </div>
      <main className="wrapper-width layout">{children}</main>
    </>
  )
}
