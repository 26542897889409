import React from "react"
import { Link } from "@reach/router"

interface Props {
  children: React.ReactNode
  disabled?: boolean
  formCard?: boolean
  loading?: boolean
  onClick?: () => void
  to?: string
  type?: "button" | "submit"
  variant?: "primary" | "secondary" | "tertiary" | "delete"
}

export const Button = ({
  children,
  disabled = false,
  formCard = false,
  loading = false,
  onClick,
  to = "",
  type = "button",
  variant = "primary",
}: Props) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`button button-${variant}${
          formCard ? " button-form-card" : ""
        }`}
      >
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  } else {
    return (
      <button
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}`}
        type={type}
        disabled={disabled}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  }
}
