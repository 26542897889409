import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

import {
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Button,
  FormLegend,
  AddressFinder,
  FormSection,
} from "../../shared"
import { NewStatusReportingGroupFormValues } from "../../../types"
import {
  returnNewStatusReportingGroup,
  handleAPIError,
} from "../../../utilities"

interface Props {
  isVisible: boolean
  dismissFunc: () => void
  setData: (array: any) => void
}

export const NewStatusReportingGroup = ({
  isVisible,
  dismissFunc,
  setData,
}: Props) => {
  const { addToast } = useToasts()
  const [searchedAddress, setSearchedAddress] = React.useState()
  const [searchedAddressError, setSearchedAddressError] = React.useState("")
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(false)

  const onSubmit = async (
    values: NewStatusReportingGroupFormValues,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    if (values.address === "") {
      setSearchedAddressError("Please find or enter an address")
      setManualAddressVisibility(true)
    } else {
      try {
        const response = await axios({
          method: "post",
          url: "/status-reporting-groups/create",
          data: returnNewStatusReportingGroup(values),
        })
        setData((statusReportingGroups: any) => [
          ...statusReportingGroups,
          response.data,
        ])
        dismissFunc()
        addToast("New status reporting group created", {
          appearance: "success",
        })
      } catch (error) {
        addToast(handleAPIError(error, "create new status reporting group"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  const initialValues: NewStatusReportingGroupFormValues = {
    code: "",
    address: "",
  }

  React.useEffect(() => {
    if (!isVisible) setManualAddressVisibility(false)

    return () => {
      setManualAddressVisibility(false)
    }
  }, [isVisible])

  return (
    <Modal
      title="New Status Reporting Group"
      isVisible={isVisible}
      dismissFunc={dismissFunc}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          code: Yup.string().required(
            "Please enter the code you'd like employees to enter"
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <FormLegend>
                Once an employee has joined a Status Reporting Group you&#39;ll
                be able to see their Fit-for-work status.
              </FormLegend>
              <FormSection>
                <Input
                  name="code"
                  label="New Status Reporting Group Code"
                  placeholder="e.g. COMPANYNAMELOCATION"
                />
                <AddressFinder
                  label="Location Address"
                  searchedAddress={searchedAddress}
                  error={searchedAddressError}
                  onChange={(address: any) => {
                    setSearchedAddress(address)
                    setFieldValue(
                      "address",
                      address && address.label ? address.label : ""
                    )
                  }}
                >
                  {manualAddressVisible ? (
                    ""
                  ) : (
                    <button
                      onClick={() => setManualAddressVisibility(true)}
                      className="label-link"
                    >
                      Enter manually
                    </button>
                  )}
                </AddressFinder>
                <div className={manualAddressVisible ? "" : "hidden"}>
                  <Input
                    name="address"
                    label="Enter Location Address"
                    placeholder="Your full address"
                  />
                </div>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="submit"
              >
                Create Status Reporting Group
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
