import { NewStatusReportingGroupFormValues } from "../../types"

export const returnNewStatusReportingGroup = ({
  code,
  address,
}: NewStatusReportingGroupFormValues) => {
  return {
    code: code,
    name: "New Test Fit for Work Status Reporting",
    address: address,
    description: "Weekly Fit for Work Status Reporting",
    company: "PNqk6QS6pK7wPkXeoKeI",
    nextRun: "now",
    accessRights: [
      {
        description: "full name",
        key: "fullName",
        model: "patient",
      },
      {
        description: "patient Id",
        key: "id",
        model: "patient",
      },
      {
        description: "current Fit for Work status",
        key: "status",
        model: "status-reporting-groups",
      },
    ],
    definition: {
      onComplete: [
        {
          type: "webhook",
          settings: {
            url: "test",
            headers: {
              "x-api-key": "test",
              "x-gtt-gsheet-id": "test",
            },
          },
        },
      ],
      tasks: [
        {
          description: "Fill out a COVID-19 Symptoms Survey",
          name: "COVID Symptoms Survey",
          type: "survey",
          settings: {
            surveyId: "Faf9SQik6ifwu3lnIExj",
            outcome: "No symptoms",
          },
          completedWithinPast: {
            days: 7,
          },
        },
        {
          description: "Complete a self test COVID test or is fully vaccinated",
          name: "COVID test",
          type: "selfTestOrFullyVaccinated",
          settings: {},
          completedWithinPast: {
            days: 7,
          },
        },
      ],
    },
    frequency: {
      days: 7,
    },
  }
}
