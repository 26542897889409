import React from "react"
import ReactSelect from "react-select"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const InputSelect = ({
  children,
  label,
  loading,
  name,
  optional,
  options,
  placeholder,
}) => {
  const [field, meta, helpers] = useField(name)
  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      <Label field={name} optional={optional} extras={children}>
        {label}
      </Label>
      <ReactSelect
        isLoading={loading}
        placeholder={placeholder ? placeholder : "Please select..."}
        styles={{
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#ffae33 !important"
              : state.isFocused
              ? "#f2f2f5"
              : "white",
          }),
        }}
        {...field}
        type="select"
        className={`input-select${
          meta.touched && meta.error ? " input-select-error" : ""
        }`}
        classNamePrefix="react-select"
        options={options}
        onChange={(opt) => helpers.setValue(opt.value)}
        value={options.find((opt) => opt.value === field.value)}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputSelect.defaultProps = {
  optional: false,
  children: "",
  loading: false,
  placeholder: "",
}

InputSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  options: PropTypes.array.isRequired,
  children: PropTypes.node,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
}
