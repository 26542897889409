import React from "react"

import { StatusReportingGroupPatientType } from "../../../../types"
import { Tag } from "../../../shared"

export const StatusReportingGroupCards = ({
  data,
}: {
  data: Array<StatusReportingGroupPatientType>
}) => (
  <section className="status-reporting-group-cards">
    {data.length > 0 &&
      data.map(
        ({
          id,
          fullName,
          vaccinated,
          fitForWork,
        }: StatusReportingGroupPatientType) => (
          <table className="status-reporting-group-card" key={id}>
            <tbody>
              <tr className="status-reporting-group-card-row">
                <td className="status-reporting-group-card-header">
                  <span className="status-reporting-group-card-row-text">
                    Patient ID
                  </span>
                </td>
                <td className="status-reporting-group-card-body">
                  <span className="status-reporting-group-card-row-text">
                    {id}
                  </span>
                </td>
              </tr>
              <tr className="status-reporting-group-card-row">
                <td className="status-reporting-group-card-header">
                  <span className="status-reporting-group-card-row-text">
                    Patient
                  </span>
                </td>
                <td className="status-reporting-group-card-body">{fullName}</td>
              </tr>
              <tr className="status-reporting-group-card-row">
                <td className="status-reporting-group-card-header">
                  <span className="status-reporting-group-card-row-text">
                    Vaccinated
                  </span>
                </td>
                <td className="status-reporting-group-card-body">
                  <span className="status-reporting-group-card-row-text">
                    {vaccinated ? "Vaccinated" : "Not vaccinated"}
                  </span>
                </td>
              </tr>
              <tr className="status-reporting-group-card-row">
                <td className="status-reporting-group-card-header">
                  <span className="status-reporting-group-card-row-text">
                    Fit for work
                  </span>
                </td>
                <td className="status-reporting-group-card-body">
                  {fitForWork ? (
                    <Tag type="success" variant="background" icon>
                      Fit for work
                    </Tag>
                  ) : (
                    <Tag type="error" variant="background" icon>
                      Not fit for work
                    </Tag>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )
      )}
  </section>
)
