import { Router } from "@reach/router"

import {
  Login,
  ForgotPassword,
  SignUp,
  CreatePassword,
  CreateAccount,
  ScrollToTop,
} from "./components"
import { ProtectedApp } from "./protected-app"
import { useAuth } from "./hooks"

import "./style/main.scss"

const App = () => {
  const authHook = useAuth()

  if (authHook?.user === null) return <div>Loading...</div>
  return authHook?.user && authHook?.signedUp ? (
    <ProtectedApp />
  ) : (
    <Router className="router">
      <ScrollToTop path="/">
        <Login default />
        <SignUp path="/sign-up" />
        <CreateAccount path="/create-account/*" />
        <ForgotPassword path="/forgotten-password" />
        <CreatePassword path="/password-reset" />
      </ScrollToTop>
    </Router>
  )
}

export default App
