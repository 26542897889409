import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

import {
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Input,
  FormSection,
  FormLegend,
} from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { InviteEmployeesModalTable } from "./InviteEmployeesModalTable"

interface Props {
  code: string
  id: string
  dismissFunc: () => void
  isVisible: boolean
}

export interface employeeType {
  email: string
  firstName: string
  lastName: string
}

export interface employeesType extends Array<employeeType> {}

export const InviteEmployeesModal = ({
  code,
  id,
  dismissFunc,
  isVisible,
}: Props) => {
  const { addToast } = useToasts()
  const [employees, setEmployees] = React.useState<employeesType>([])
  const [loading, setLoading] = React.useState(false)

  const initialValues: employeeType = {
    email: "",
    firstName: "",
    lastName: "",
  }

  const addEmployee = (
    values: employeeType,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: Function; resetForm: Function }
  ) => {
    setEmployees((currentEmployees) => [...currentEmployees, values])
    setSubmitting(false)
    resetForm(initialValues)
  }

  const sendEmployeeInvites = async () => {
    if (employees.length > 0) {
      setLoading(true)
      try {
        await axios({
          method: "post",
          url: "invitations/employee",
          data: { code: code, users: employees },
        })
        addToast("Employee invites sent", {
          appearance: "success",
        })
        setLoading(false)
        dismissFunc()
      } catch (error) {
        addToast(handleAPIError(error, "send employee invites"), {
          appearance: "error",
        })
        setLoading(false)
      }
    } else {
      addToast("Could not send employee invites, no employees added", {
        appearance: "error",
      })
    }
  }

  React.useEffect(() => {
    if (!isVisible) {
      setEmployees([])
    }
  }, [isVisible])

  return (
    <Modal
      isVisible={isVisible}
      dismissFunc={dismissFunc}
      title="Invite Employees"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your employee's email"),
          firstName: Yup.string().required(
            "Please enter your employee's first name"
          ),
          lastName: Yup.string().required(
            "Please enter your employee's last name"
          ),
        })}
        onSubmit={addEmployee}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormLegend>
                <p>
                  Existing GotTheTest users will be invited to join &#39;{code}
                  &#39; and asked to convert their account to the Enterprise
                  plan.
                </p>
                <p>
                  New GotTheTest users will be invited to create an Enterprise
                  plan account and will be automatically included in &#39;{code}
                  &#39;.
                </p>
              </FormLegend>
              <FormSection>
                <Input label="Email" name="email" />
                <div />
                <Input label="First name" name="firstName" />
                <Input label="Last name" name="lastName" />
              </FormSection>
              <Button
                variant="secondary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Add employee
              </Button>
              <InviteEmployeesModalTable
                employees={employees}
                setEmployees={setEmployees}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => sendEmployeeInvites()}
                loading={loading}
                disabled={loading}
              >
                Invite employees
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
