import React from "react"

interface Props {
  columns?: number
  children: React.ReactNode
}

export const FormSection = ({ columns = 2, children }: Props) => (
  <section className={`form-section form-section-${columns}`}>
    {children}
  </section>
)
