import { Router } from "@reach/router"

import { useAuth } from "../../hooks"
import { VerifyEmail } from "./VerifyEmail"
import { Login, ScrollToTop } from ".."

export const CreateAccount = () => {
  const authHook = useAuth()

  return authHook?.user && authHook?.creatingAccount ? (
    <Router>
      <ScrollToTop path="/">
        <VerifyEmail path="/confirm-email" />
      </ScrollToTop>
    </Router>
  ) : (
    <Login />
  )
}
