import React from "react"

interface Props {
  icon:
    | "arrow-left"
    | "arrow-right"
    | "arrow-top"
    | "arrow-bottom"
    | "eye"
    | "eye-off"
    | "cross"
    | "more"
  onClick: () => void
}

export const IconButton = ({ icon, onClick }: Props) => (
  <button type="button" className="icon-button" onClick={onClick}>
    <span className={`icon-button-icon icon-${icon}`}></span>
  </button>
)
