import React from "react"
import PropTypes from "prop-types"

export const Label = ({ field, children, optional, extras }) => (
  <label htmlFor={field} className="label">
    <span className="label-text">{children}</span>
    {optional && <span className="label-optional-text">Optional</span>}
    {extras}
  </label>
)

Label.defaultProps = {
  optional: false,
  extras: "",
}

Label.propTypes = {
  field: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  extra: PropTypes.node,
}
