import React from "react"

import { employeesType } from ".."
import { IconButton, EmptyState } from "../../../../shared"

interface Props {
  employees: employeesType
  setEmployees: Function
}

export const InviteEmployeesModalTable = ({
  employees,
  setEmployees,
}: Props) => {
  return (
    <div className="mb-3">
      <h2 className="text-small-bold mt-4 mb-4">Employees</h2>
      {employees.length > 0 ? (
        <table className="list-page-table">
          <thead className="list-page-table-header">
            <tr>
              <td className="list-page-table-header-cell">Email</td>
              <td className="list-page-table-header-cell">First name</td>
              <td className="list-page-table-header-cell">Last name</td>
              <td className="list-page-table-header-cell" />
            </tr>
          </thead>
          <tbody className="list-page-table-body">
            {employees.map((employee) => (
              <tr className="list-page-table-body-row" key={employee.email}>
                <td className="list-page-table-body-cell">
                  <span className="list-page-table-body-cell-text">
                    {employee.email}
                  </span>
                </td>
                <td className="list-page-table-body-cell">
                  <span className="list-page-table-body-cell-text list-page-table-body-cell-bold">
                    {employee.firstName}
                  </span>
                </td>
                <td className="list-page-table-body-cell">
                  <span className="list-page-table-body-cell-text list-page-table-body-cell-bold">
                    {employee.lastName}
                  </span>
                </td>
                <td className="list-page-table-body-cell list-page-table-body-cell-button">
                  <IconButton
                    icon="cross"
                    onClick={() =>
                      setEmployees((currentEmployees: employeesType) =>
                        currentEmployees.filter(
                          (currentEmployee) =>
                            currentEmployee.email !== employee.email
                        )
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState>
          <p className="text-small">Add employees using the form above.</p>
          <p className="text-small">
            When you’re ready, click &#39;Invite employees&#39; to send email
            invitations.
          </p>
        </EmptyState>
      )}
    </div>
  )
}
