import React from "react"

interface Props {
  children: React.ReactNode
  href?: string
  onClick?: () => void
}

export const ButtonLink = ({
  children,
  href = "",
  onClick = undefined,
  ...props
}: Props) => {
  if (onClick) {
    return (
      <button onClick={onClick} className="button-link" {...props}>
        {children}
      </button>
    )
  } else if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="button-link"
        {...props}
      >
        {children}
      </a>
    )
  } else {
    return null
  }
}
