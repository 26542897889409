import React from "react"
import PropTypes from "prop-types"
import { Form, useFormikContext } from "formik"

const FormScrollIfError = ({ children, variant }) => {
  const { isValidating, isValid } = useFormikContext()
  React.useEffect(() => {
    if (!isValidating && !isValid) {
      const element = document.querySelector(".form-error")

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        })
      }
    }
  }, [isValidating, isValid])

  return (
    <Form className={variant !== "no-style" ? `form-${variant}` : ""}>
      {children}
    </Form>
  )
}

FormScrollIfError.defaultProps = {
  variant: "no-style",
}

FormScrollIfError.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["no-style", "card", "card-small"]),
}

export default FormScrollIfError
