import React from "react"
import { Link } from "@reach/router"

interface Props {
  to: string
  children: React.ReactNode
  chevron: boolean
}

export const Breadcrumb = ({ to, children, chevron }: Props) => (
  <li className="breadcrumb">
    {chevron && <span className="icon-chevron-right breadcrumb-icon" />}
    <Link className="breadcrumb-link" to={to}>
      {children}
    </Link>
  </li>
)
