import React from "react"
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

import { firebaseApp } from "../../constants"

const auth = getAuth(firebaseApp)

interface AuthObject {
  creatingAccount: boolean
  signedUp: boolean
  user: any
  accountApproved: boolean
  providerData: any
  setCreatingAccount: any
  setSignedUp: any
  setAccountApproved: any
  setProviderData: any
  signIn: any
  signOut: any
  signUp: any
}

const AuthContext = React.createContext<AuthObject | null>(null)

export function ProvideAuth({ children }: { children: React.ReactNode }) {
  const auth: AuthObject = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}

function useProvideAuth() {
  const { addToast } = useToasts()
  const [user, setUser] = React.useState(false)
  const [signedUp, setSignedUp] = React.useState(false)
  const [creatingAccount, setCreatingAccount] = React.useState(false)
  const [accountApproved, setAccountApproved] = React.useState(false)
  const [providerData, setProviderData] = React.useState({})

  const signUp = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
    } catch (error: any) {
      switch (error.code) {
        case "auth/email-already-in-use":
          addToast(
            "Sorry, this email is already in use by another account, please use a different email address",
            {
              appearance: "error",
            }
          )
          break
        default:
          addToast(`Could not create account, ${error.message}`, {
            appearance: "error",
          })
      }
    }
  }

  const signIn = async ({
    email,
    password,
    errorFunc,
  }: {
    email: string
    password: string
    errorFunc: any
  }) => {
    try {
      setPersistence(auth, browserSessionPersistence)
      const response = await signInWithEmailAndPassword(auth, email, password)
      await setBearerToken(response.user, setUser)
    } catch (error: any) {
      switch (error.code) {
        case "auth/user-not-found":
          addToast(
            "Sorry, we can't find an account with those details, please try again",
            {
              appearance: "error",
            }
          )
          break
        case "auth/wrong-password":
          addToast(
            "Sorry, we can't find an account with those details, please try again",
            {
              appearance: "error",
            }
          )
          break
        case "auth/too-many-requests":
          addToast(
            "Too many login attempts, your account has been temporarily disabled. Please try again in 5 minutes",
            {
              appearance: "error",
            }
          )
          break
        default:
          addToast("Something went wrong, please try again", {
            appearance: "error",
          })
      }
      errorFunc && errorFunc()
    }
  }

  const signOutFunc = async () => {
    try {
      setUser(false)
      setSignedUp(false)
      await signOut(auth)
    } catch (error) {
      addToast("Could not sign out, please try again", {
        appearance: "error",
      })
    }
  }

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setBearerToken(user, setUser)
      } else {
        setUser(false)
      }
    })
    return () => unsubscribe()
  }, [])

  return {
    user,
    signedUp,
    creatingAccount,
    providerData,
    accountApproved,
    setCreatingAccount,
    setSignedUp,
    setProviderData,
    setAccountApproved,
    signUp,
    signIn,
    signOut: signOutFunc,
  }
}

const setBearerToken = async (firebaseUser: any, setAppUser: any) => {
  const token = await firebaseUser.getIdToken()
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
  // Must only set user and active app after Bearer token is set
  setAppUser(firebaseUser)
}
