import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import { Label } from ".."

export const Input = ({
  autocomplete,
  label,
  name,
  optional,
  placeholder,
  type,
}) => {
  const [field, meta] = useField(name)

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      {label && (
        <Label field={name} optional={optional}>
          {label}
        </Label>
      )}
      <input
        className={`input${meta.touched && meta.error ? " input-error" : ""}`}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        autoComplete={autocomplete ? autocomplete : name}
        {...field}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

Input.defaultProps = {
  optional: false,
  label: "",
  placeholder: "",
  type: "text",
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  autocomplete: PropTypes.string,
}
